html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Dela400';
  font-style: normal;
  line-height: normal;
  src:
    url('../src/assets/fonts/DelaGothicOne-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Sans400';
  font-style: normal;
  line-height: normal;
  src:
    url('../src/assets/fonts//SourceSans3-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Sans600';
  font-style: normal;
  line-height: normal;
  src:
    url('../src/assets/fonts//SourceSans3-VariableFont_wght.ttf') format('truetype');
}
